<template>
  <div>
    <el-row v-if="!showEdit">
      <el-col :span="23">
        <el-button-group>
          <el-button
            @click="changeShaixuan"
            :type="changeIndex == 1 ? 'primary' : ''"
            :size="allSize"
            >新的筛选</el-button
          >
          <el-button
            @click="changeShaixuan"
            :type="changeIndex == 2 ? 'primary' : ''"
            :size="allSize"
            >已保存</el-button
          >
        </el-button-group>
      </el-col>
      <el-col :span="1">
        <div @click="closeWhere">
          <i class="el-icon-close"></i>
        </div>
      </el-col>
    </el-row>

    <el-row v-else style="font-size: 24px">
      <el-col :span="2">
        <i class="el-icon-back" @click="closeFiter"></i>
      </el-col>
      <el-col :span="22" style="text-align: center">
        <el-input
          v-model="filterInfo.title"
          placeholder="请输入内容"
          style="width: 120px"
        ></el-input
      ></el-col>
    </el-row>
    <div v-if="changeIndex == 1 && isfilter == false">
      <el-empty
        description="添加筛选条件，查询工作表数据"
        :image-size="160"
        v-if="showAdd == false"
      >
        <el-button
          type="primary"
          :size="allSize"
          icon="el-icon-plus"
          v-if="changeIndex == 1"
          @click="openAdd"
          >新增筛选条件</el-button
        >
      </el-empty>

      <el-row
        style="margin-left: 20px; margin-top: 20px"
        v-for="(item, index) in whereArray"
        :key="index"
      >
        <el-row>
          <el-col :span="3" v-if="index == 0">
            <span style="color: #757575">当 </span>
          </el-col>
          <el-col :span="3" v-else>
            <el-dropdown
              @command="handleCommandFilter"
              :szie="allSize"
              trigger="click"
            >
              <span class="el-dropdown-link"
                >{{ filterLists[item.fileterIndex].title }}
                <i class="el-icon-arrow-down el-icon--right"></i
              ></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="index + '-' + index1"
                  v-for="(item1, index1) in filterLists"
                  :key="index1"
                  >{{ item1.title }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
          <el-col :span="8"
            ><b>{{ item.title }}</b></el-col
          >
          <el-col :span="12">
            <el-dropdown
              @command="handleCommandCondition"
              :szie="allSize"
              trigger="click"
            >
              <span class="el-dropdown-link"
                >{{ fitlers[item.conditionIndex].title }}
                <i class="el-icon-arrow-down el-icon--right"></i
              ></span>
              <el-dropdown-menu slot="dropdown">
                <div v-for="(item1, index1) in fitlers" :key="index1">
                  <el-dropdown-item
                    v-if="item1.belong.includes(item.type)"
                    :command="index + '-' + index1"
                    >{{ item1.title }}</el-dropdown-item
                  >
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
          <el-col :span="1">
            <div @click="moveWhere(index)">
              <i class="el-icon-close"></i>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px; margin-left: 20px">
          <el-row v-if="item.type == 'string'">
            <el-input
              :placeholder="
                item.condition == 'null' || item.condition == 'not null'
                  ? ''
                  : '请输入内容'
              "
              v-model="item.value"
              clearable
              :disabled="
                item.condition == 'null' || item.condition == 'not null'
                  ? true
                  : false
              "
              :size="allSize"
            >
            </el-input>
          </el-row>

          <el-row v-if="item.type == 'date'">
            <el-date-picker
              style="width: 320px"
              v-model="item.value"
              type="daterange"
              :size="allSize"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              align="right"
            >
            </el-date-picker>
          </el-row>

          <el-row v-if="item.type == 'int'">
            <div
              v-if="item.condition == 'null' || item.condition == 'not null'"
            >
              <el-input v-model="item.value" disabled :size="allSize">
              </el-input>
            </div>
            <div v-else>
              <div
                v-if="
                  item.condition == 'between' || item.condition == 'not between'
                "
              >
                <el-input
                  placeholder="请输入内容"
                  type="number"
                  v-model="item.value[0]"
                  clearable
                  :size="allSize"
                  style="width: 153.5px"
                >
                </el-input>
                -
                <el-input
                  placeholder="请输入内容"
                  v-model="item.value[1]"
                  type="number"
                  clearable
                  :size="allSize"
                  style="width: 153.5px"
                >
                </el-input>
              </div>
              <div v-else>
                <el-input
                  placeholder="请输入内容"
                  v-model="item.value"
                  type="number"
                  :size="allSize"
                >
                </el-input>
              </div>
            </div>
          </el-row>

          <el-row v-if="item.type == 'enum'">
            <div v-if="item.condition == 'in' || item.condition == 'not in'">
              <el-checkbox-group v-model="item.value" :size="allSize">
                <el-checkbox-button
                  v-for="(item2, index2) in item.values"
                  :label="item2.value"
                  :key="index2"
                  >{{ item2.title }}</el-checkbox-button
                >
              </el-checkbox-group>
            </div>
            <div v-if="item.condition == '=' || item.condition == '<>'">
              <el-radio-group v-model="item.value" :size="allSize">
                <el-radio-button
                  :label="item2.value"
                  :key="index2"
                  v-for="(item2, index2) in item.values"
                  >{{ item2.title }}</el-radio-button
                >
              </el-radio-group>
            </div>
            <div
              v-if="item.condition == 'null' || item.condition == 'not null'"
            >
              <el-input v-model="item.value" disabled :size="allSize">
              </el-input>
            </div>
          </el-row>

          <el-row v-if="item.type == 'key'">
            <div
              v-if="item.condition == 'null' || item.condition == 'not null'"
            >
              <el-input disabled v-model="item.value" :size="allSize">
              </el-input>
            </div>
            <div v-else>
              <el-select
                v-model="item.value"
                :size="allSize"
                filterable
                clearable
                :multiple="
                  item.condition == 'in' || item.condition == 'not in'
                    ? true
                    : false
                "
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in item.values"
                  :key="index"
                  :label="item.tree == 1 ? item.html + item.title : item.title"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-row>
        </el-row>
      </el-row>
      <!--新增条件--->
      <el-row
        style="margin-left: 20px; margin-top: 20px"
        v-if="showAdd == true"
      >
        <el-col :span="8">
          <el-dropdown
            @command="handleCommand"
            :szie="allSize"
            trigger="click"
            class="dropdown"
          >
            <span class="el-dropdown-link"> +添加筛选条件 </span>

            <el-dropdown-menu slot="dropdown">
              <div class="drop">
                <div style="width: 100%">
                  <el-input
                    placeholder="请输入内容"
                    clearable
                    v-model="filedKeyword"
                    prefix-icon="el-icon-search"
                    @input="inputKeyword"
                    class="input"
                    :size="allSize"
                  >
                  </el-input>
                </div>
              </div>
              <div class="project-dropdown">
                <el-dropdown-item
                  :divided="index == 0 ? true : false"
                  v-for="(item, index) in selectFileds"
                  :key="index"
                  :command="item.value + '-' + item.title + '-' + index"
                  >{{ item.title }}</el-dropdown-item
                >
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="16">
          <el-button type="primary" :size="allSize" @click="searchData"
            >查询</el-button
          >
          <el-button :size="allSize" @click="saveWhereData">清空</el-button>
          <el-button
            :size="allSize"
            @click="openCreate"
            v-if="
              whereArray.some((item) => {
                return item.value;
              })
            "
            type="success"
            >保存</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <div v-if="!showEdit">
        <el-card class="top" style="height: 240px">
          <div class="clearfix">
            <span>个人</span>
          </div>
          <div class="box-card1">
            <div
              v-for="(item, index) in gerenList"
              :key="index"
              class="text item show"
            >
              <el-tag
                style="width: 96%"
                @click="selectWhere(index, '2')"
                :type="gerenWhereIndex == index ? '' : 'info'"
              >
                <span
                  >{{ item.title }}

                  <el-dropdown style="float: right; padding: 3px 0">
                    <span class="el-dropdown-link">
                      <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="el-icon-s-tools"
                        ><span @click="editFiter(index, 'geren')">编辑</span>
                      </el-dropdown-item>
                      <el-dropdown-item icon="el-icon-delete-solid">
                        <span @click="delFiter(index, item.value, 'geren')">
                          删除
                        </span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </el-tag>
            </div>
          </div>
        </el-card>
        <el-card style="height: 240px" class="top">
          <div class="clearfix">
            <span>公共</span>
          </div>
          <div class="box-card1">
            <div
              v-for="(item, index) in gonggongList"
              :key="index"
              class="text item show"
            >
              <el-tag
                style="width: 96%"
                :type="gonggongWhereIndex == index ? '' : 'info'"
                @click="selectWhere(index, '1')"
              >
                <span
                  >{{ item.title }}

                  <el-dropdown style="float: right; padding: 3px 0">
                    <span class="el-dropdown-link">
                      <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="el-icon-s-tools"
                        ><span @click="editFiter(index, 'gonggong')">编辑</span>
                      </el-dropdown-item>
                      <el-dropdown-item icon="el-icon-delete-solid"
                        ><span @click="delFiter(index, item.value, 'gonggong')">
                          删除
                        </span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </el-tag>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <el-dialog
      title="保存筛选器"
      :visible.sync="showCreate"
      :close-on-click-modal="false"
      :modal="false"
      width="40%"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        :label-width="formLabelWidth"
      >
        <el-form-item label="标题" prop="title">
          <el-col :span="10">
            <el-input
              v-model="formData.title"
              autocomplete="off"
              :size="allSize"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="使用范围" prop="type">
          <el-col :span="10">
            <el-radio-group v-model="formData.type" :size="allSize">
              <el-radio :label="1">公共</el-radio>
              <el-radio :label="2">个人</el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showCreate = false" :size="allSize">取 消</el-button>
        <el-button type="primary" @click="saveData()" :size="allSize"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
let _this;
export default {
  name: "FilterView",
  props: {
    url: {
      type: String,
    },
    isfilter: {
      type: Boolean,
    },
  },
  data() {
    return {
      changeIndex: 1,
      showWhere: false,
      showAdd: false,
      showEdit: false,
      whereArray: [],
      allSize: "mini",
      filterIndex: 0,
      filterLists: [
        { title: "且", value: "and" },
        { title: "或", value: "or" },
      ],
      fitlers: [
        { title: "包含", value: "like", belong: "string" },
        { title: "不包含", value: "not like", belong: "string" },
        { title: "等于", value: "=", belong: "string|int|enum|key" },
        { title: "不等于", value: "<>", belong: "string|int|enum|key" },
        { title: "大于", value: ">", belong: "int" },
        { title: "大于等于", value: ">=", belong: "int" },
        { title: "小于", value: "<", belong: "int" },
        { title: "小于等于", value: "<=", belong: "int" },
        { title: "为空", value: "null", belong: "string|int|enum|key|path" },
        {
          title: "不为空",
          value: "not null",
          belong: "string|int|enum|key|path",
        },
        { title: "在范围内", value: "between", belong: "int|date" },
        { title: "不在范围内", value: "not between", belong: "int|date" },
        { title: "其中一个", value: "in", belong: "enum|key" },
        { title: "不是任何一个", value: "not in", belong: "enum|key" },
      ],
      filedKeyword: "",
      filedLists: [],
      selectFileds: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(new Date().setHours(23, 59, 59, 999));
              const start = new Date(new Date().setHours(0, 0, 0, 0));
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(new Date().setHours(23, 59, 59, 999));
              const start = new Date(new Date().setHours(0, 0, 0, 0));
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "本周",
            onClick(picker) {
              const current = new Date();
              let nowDayOfWeek = current.getDay();
              if (nowDayOfWeek === 0) nowDayOfWeek = 7;
              const dayNum = 1 * 24 * 60 * 60 * 1000;
              const start = new Date(
                current.valueOf() - (nowDayOfWeek - 1) * dayNum
              );
              const end = new Date(new Date(start).valueOf() + 6 * dayNum);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "本月",
            onClick(picker) {
              const start = new Date().setDate(1);

              const end = new Date();
              const currentMonth = end.getMonth();
              const nextMonth = currentMonth + 1;
              const nextMonthFirstDay = new Date(
                end.getFullYear(),
                nextMonth,
                1
              );
              const oneDay = 24 * 60 * 60 * 1000;
              picker.$emit("pick", [
                new Date(new Date(start).setHours(0, 0, 0, 0)),
                new Date(
                  new Date(nextMonthFirstDay - oneDay).setHours(23, 59, 59, 999)
                ),
              ]);
            },
          },
          {
            text: "本年",
            onClick(picker) {
              const end = new Date();
              end.setFullYear(end.getFullYear() + 1); // 设置到明年
              end.setMonth(0); // 明年的0月，也就是对应到1月，是存在的哦，不是不存在的0
              end.setDate(0); // 明年的0日
              const start = new Date();
              start.setDate(1);
              start.setMonth(0);

              picker.$emit("pick", [
                new Date(new Date(start).setHours(0, 0, 0, 0)),
                new Date(new Date(end).setHours(0, 0, 0, 0)),
              ]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(new Date().setHours(23, 59, 59, 999));
              const start = new Date(new Date().setHours(0, 0, 0, 0));
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },

          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(new Date().setHours(23, 59, 59, 999));
              const start = new Date(new Date().setHours(0, 0, 0, 0));
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(new Date().setHours(23, 59, 59, 999));
              const start = new Date(new Date().setHours(0, 0, 0, 0));
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      formData: {
        title: "",
        type: 1,
        content: "",
        tablename: "",
      },
      rules: {
        title: [
          {
            required: true,
            message: "请填写标题",
            trigger: "blur",
          },
        ],
      },
      formLabelWidth: "120px",
      showCreate: false,
      gerenList: [],
      gonggongList: [],
      gerenIndex: -1,
      gonggongIndex: -1,
      gerenWhereIndex: -1,
      gonggongWhereIndex: -1,
      filterInfo: {},
    };
  },
  mounted() {
    _this = this;
    _this.getFieldList();
  },

  methods: {
    changeShaixuan() {
      _this.changeIndex = _this.changeIndex == 1 ? 2 : 1;
      if (_this.changeIndex == 2) {
        _this.$nextTick(() => {
          _this.getFilterList();
        });
      }
    },
    openAdd() {
      _this.showAdd = true;
    },
    closeWhere() {
      _this.$emit("oncloseWhere");
    },
    searchData() {
      let whereArray = _this.whereArray;
      for (let index = 0; index < whereArray.length; index++) {
        whereArray[index]["values"] = [];
      }
      _this.$emit("onSeachData", whereArray, false);
    },
    saveWhereData() {
      _this.whereArray = [];
      _this.showAdd = false;
      _this.$emit("onSeachData", [], false);
    },
    inputKeyword(keyword) {
      this.selectFileds = this.filedLists.filter((item) => {
        return item.title.toLowerCase().includes(keyword.toLowerCase());
      });
    },
    moveWhere(index) {
      this.whereArray.splice(index, 1);
      if (this.whereArray.length == 0) {
        this.showAdd = false;
      }
    },
    handleCommand(command) {
      var commandArray = command.split("-");
      var type = this.selectFileds[commandArray[2]].type;
      var conditionIndex = 0;
      var condition = "like";
      var value = "";
      var values = "";
      var otherTableName = this.selectFileds[commandArray[2]].other_table_name;
      var istree = this.selectFileds[commandArray[2]].istree;
      if (type == "date") {
        value = [];
        for (var i = 0; i <= this.fitlers.length; i++) {
          if (this.fitlers[i].belong.includes("date")) {
            conditionIndex = i;
            condition = this.fitlers[i].value;
            break;
          }
        }
      }
      if (type == "path") {
        for (var i = 0; i <= this.fitlers.length; i++) {
          if (this.fitlers[i].belong.includes("path")) {
            conditionIndex = i;
            condition = this.fitlers[i].value;
            break;
          }
        }
      }
      if (type == "key") {
        value = [];
        values = this.selectFileds[commandArray[2]].values;
        for (var i = 0; i <= this.fitlers.length; i++) {
          if (this.fitlers[i].belong.includes("key")) {
            conditionIndex = i;
            condition = this.fitlers[i].value;
            break;
          }
        }
      }

      if (type == "int") {
        value = [0, 0];
        for (var i = 0; i <= this.fitlers.length; i++) {
          if (this.fitlers[i].belong.includes("int")) {
            conditionIndex = i;
            condition = this.fitlers[i].value;
            break;
          }
        }
      }

      if (type == "enum") {
        values = this.selectFileds[commandArray[2]].values;
        value = [];
        for (var i = 0; i <= this.fitlers.length; i++) {
          if (this.fitlers[i].belong.includes("enum")) {
            conditionIndex = i;
            condition = this.fitlers[i].value;
            break;
          }
        }
        if (condition == "=" || condition == "<>") {
          value = "";
        }
      }

      this.whereArray.push({
        field: commandArray[0],
        title: commandArray[1],
        value: value,
        fileterIndex: 0,
        filter: "and",
        conditionIndex: conditionIndex,
        condition: condition,
        type: type,
        values: values,
        istree: istree,
        tablename: otherTableName,
      });
    },
    handleCommandFilter(command) {
      var indexArray = command.split("-");
      this.whereArray[indexArray[0]]["fileterIndex"] = indexArray[1];
      this.whereArray[indexArray[0]]["filter"] =
        this.filterLists[indexArray[1]].value;
    },
    handleCommandCondition(command) {
      var indexArray = command.split("-");
      var conditionvalue = this.fitlers[indexArray[1]].value;
      var value = "";
      if (
        conditionvalue == "in" ||
        conditionvalue == "not in" ||
        conditionvalue == "between" ||
        conditionvalue == "not between"
      ) {
        value = [];
      }

      this.$nextTick(() => {
        this.whereArray[indexArray[0]]["conditionIndex"] = indexArray[1];
        this.whereArray[indexArray[0]]["value"] = value;
        this.whereArray[indexArray[0]]["condition"] =
          this.fitlers[indexArray[1]].value;
      });
    },
    getFieldList() {
      _this
        .postRequest(_this.url + "/getFieldsList")
        .then((resp) => {
          if (resp.code == 200) {
            _this.filedLists = resp.data;
            _this.selectFileds = resp.data;
          }
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.message,
          });
        });
    },
    closeFiter() {
      _this.showEdit = false;
      _this.changeIndex = 2;
    },
    editFiter(index, type) {
      _this.showEdit = true;
      _this.changeIndex = 1;
      _this.gerenWhereIndex = -1;
      _this.gonggongWhereIndex = -1;
      _this.showAdd = true;
      if ((type = "geren")) {
        _this.filterInfo = _this.gerenList[index];
        _this.whereArray = _this.gerenList[index]["content"];
      } else {
        _this.filterInfo = _this.gonggongList[index];
        _this.whereArray = _this.gonggongList[index]["content"];
      }

      _this.$emit("onSeachData", [], false);
    },
    delFiter(index, id, type) {
      _this
        .$confirm("是否删除该信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.deleteRequest("/filter/delete?id=" + id).then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              if (type == "geren") {
                if (_this.gerenWhereIndex == index) {
                  _this.$emit("onSeachData", [], false);
                }
                _this.gerenList.splice(index, 1);
              } else {
                if (_this.gonggongWhereIndex == index) {
                  _this.$emit("onSeachData", [], false);
                }
                _this.gonggongList.splice(index, 1);
              }
            } else {
              this.$message({
                type: "error",
                message: resp.msg,
              });
            }
          });
        });
    },
    getFilterList() {
      _this
        .postRequest("/filter/getFilterList", { tablename: _this.url })
        .then((resp) => {
          if (resp.code == 200) {
            _this.gerenList = resp.data.gerenList;
            _this.gonggongList = resp.data.gonggongList;
          }
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.message,
          });
        });
    },
    //操作筛选条件
    handleClick(index, type) {
      if (type == 2) {
        _this.gerenIndex = index;
      } else {
        _this.gonggongIndex = index;
      }
    },
    selectWhere(index, type) {
      let whereArray = [];
      let title = "";
      if (type == 2) {
        _this.gerenWhereIndex = index;
        whereArray = _this.gerenList[index]["content"];
        title = _this.gerenList[index]["title"];
        _this.gonggongWhereIndex = -1;
      } else {
        _this.gonggongWhereIndex = index;
        whereArray = _this.gonggongList[index]["content"];
        title = _this.gonggongList[index]["title"];
        _this.gerenWhereIndex = -1;
      }
      for (let index = 0; index < whereArray.length; index++) {
        whereArray[index]["values"] = [];
      }

      _this.$emit(
        "onSeachData",
        { title: title, whereArray: whereArray },
        true
      );
    },
    openCreate() {
      if (_this.showEdit == true) {
        _this.editData();
      } else {
        _this.showCreate = true;
      }
    },
    editData() {
      let formData = {
        title: _this.filterInfo.title,
        id: _this.filterInfo.value,
        content: _this.filterInfo.content,
      };
      _this.postRequest("/filter/save", formData).then((resp) => {
        if (resp.code == 200) {
          _this.$message({
            type: "success",
            message: resp.msg,
          });
          _this.showEdit = false;
          _this.showAdd = false;
          _this.changeIndex = 2;
          _this.whereArray = [];
          _this.getFilterList();
        } else {
          _this.$message({
            type: "error",
            message: resp.msg,
          });
        }
      });
    },
    saveData() {
      _this.formData.content = _this.whereArray;
      _this.formData.tablename = _this.url;
      _this.$refs["formData"].validate((valid) => {
        if (valid) {
          _this.postRequest("/filter/save", _this.formData).then((resp) => {
            if (resp.code == 200) {
              _this.$message({
                type: "success",
                message: resp.msg,
              });
              _this.showCreate = false;
            } else {
              _this.$message({
                type: "error",
                message: resp.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  cursor: pointer;
}
.drop {
  padding: 10px;
}
.input {
  width: 100px;
  margin: 0 auto;
  width: 100%;
}

.project-dropdown {
  //设置高度才能显示出滚动条 !important
  height: 300px;
  overflow: auto;
}
.project-dropdown::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
.project-dropdown::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.el-dropdown-link {
  color: #409eff;
}
.top {
  margin-top: 4px;
}

.text {
  font-size: 14px;
}
.box-card1 {
  height: 200px;
  overflow: auto;
  margin-top: 6px;
}
.item {
  margin-bottom: 3px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 1px rgba(136, 136, 136, 0.3);
  background-color: rgb(238, 241, 245);
}
.edit {
  display: none;
}
.show:hover .edit {
  display: block;
}

.inputDeep >>> .el-input__inner {
  border: 0;
}
</style>
